html {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  line-height: 1.5;
}
body {
  font-family: "Montserrat" !important;
  margin: 0;
}
* {
  box-sizing: border-box;
}
*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;
}
table {
  border-collapse: collapse;
}
h1 {
  font-size: 40px;
}
h2 {
  font-size: 35px;
}
h3 {
  font-size: 30px;
}
h4 {
  font-size: 25px;
}
h5 {
  font-size: 22px;
}
h6 {
  font-size: 19px;
}
header {
  background-color: #292a3c;
  padding: 12px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 999;
  line-height: 20px;
  min-height: 56px;
}

header .ant-dropdown-trigger {
  color: #fff;
}
header.ant-layout-header {
  padding: 0 24px;
  background: #292a3c;
}
.ant-menu-horizontal {
  line-height: 60px;
}
.ant-menu-submenu {
  display: block;
}
.ant-menu-submenu .first-name {
  font-weight: bold;
}
.ant-btn.ant-btn-primary:focus span {
  color: #fff;
}
.ant-btn[disabled] > span {
  color: #a09797;
}
.ant-btn-link {
  color: #0085b3 !important;
}
.ant-btn-secondary:hover {
  color: #002664 !important;
  border-color: #002664 !important;
}
.ant-table-column-title {
  font-weight: bold;
  color: #002664;
}
.btn-background-navy.ant-btn:active,
.btn-background-navy.ant-btn.active {
  color: #101c44;
  border-color: #101c44;
}
.btn-background-navy.ant-btn:active span,
.btn-background-navy.ant-btn.active span,
.ant-btn:focus > span {
  color: #101c44;
}
tr:nth-child(even) {
  background-color: #f4f4f7;
}

.ant-menu-horizontal,
.ant-menu-submenu-horizontal {
  border-bottom: none;
}

.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  top: 0px;
}
.ant-menu-horizontal > .ant-menu-item > a,
.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
  color: #fff !important;
}
.ant-menu-horizontal > .ant-menu-item-selected > a {
  color: #fff !important;
}
.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title:hover {
  color: #1cb9c8 !important;
}
.ant-menu-item-selected {
  color: #002664 !important;
  border-color: #002664 !important;
}
.ant-menu-item:hover,
.ant-menu-submenu:hover {
  color: #1cb9c8 !important;
  border-color: #1cb9c8 !important;
}

.ant-menu-horizontal > .ant-menu-item > a:hover,
.ant-menu-horizontal > .ant-menu-submenu > a:hover {
  color: #1cb9c8 !important;
}

.ant-menu-horizontal > .ant-menu-submenu-open {
  color: #1cb9c8 !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background-color: #002664 !important;
  color: #ffffff !important;
}
.ant-card-head {
  background-color: #2e5299 !important;
  color: #ffffff !important;
}
.secondary-btn {
  background-color: #2e5299 !important;
  color: #ffffff !important;
}
.delete-btn {
  background-color: #d7153a !important;
  color: #ffffff !important;
  border-color: #d7153a !important;
}
.mt-2 {
  margin-top: 0.5rem;
}
.w-full {
  width: 100%;
}
.w-1-3 {
  width: 33.33%;
}
.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.border {
  border-width: 1px;
}
.rounded {
  border-radius: 0.25rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}
.mr-4 {
  margin-right: 1rem;
}
.t0 {
  top: 0;
}
.r0 {
  right: 0;
}
.appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.display-inline {
  display: inline-block;
}
.btn-background-navy {
  background-color: #101c44;
  padding: 12px 30px;
}
.btn-padding {
  padding: 12px 60px;
}
.btn-background-navy span {
  color: white;
}
.btn-background-navy:hover,
.btn-background-navy:focus,
.btn-background-navy:active,
.btn-background-navy.active {
  background-color: #fff;
}
.btn-login {
  background-color: #101c44;
}
.btn-background-navy:hover,
.btn-background-navy:focus {
  color: #101c44;
  border-color: #101c44;
}
.btn-background-navy:hover span,
.btn-background-navy:focus span {
  color: #101c44;
}
.btn-background-navy:hover span {
  color: #101c44;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.table-list {
  border: 1px solid #dcdcdc;
  -webkit-box-shadow: -10px 3px 8px 1px #dcdcdc;
  -moz-box-shadow: -10px 3px 8px 1px #dcdcdc;
  box-shadow: -10px 3px 8px 1px #dcdcdc;
}
.color-gray-th {
  background-color: #f2f2f2;
}
.table-list tr th,
.table-list tbody tr td {
  border-width: 1px 0px;
}
.table-list th {
  color: #8c8c8c;
  text-align: left;
}
.table-list td {
  color: #000000;
  font-weight: bold;
}
.h-4 {
  height: 1rem;
}
.h-6 {
  height: 2rem;
}
.h-6 {
  height: 2rem;
}
.ant-table-wrapper,
.ant-form {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.ant-table-thead th span {
  color: #8c8c8c;
}
.ant-table-body tr td,
.ant-table-body tr td button {
  color: #000;
  font-weight: bold;
}
.ant-table-wrapper .ant-pagination-item-active {
  background-color: #101c44;
  border-color: #101c44;
}
.ant-table-wrapper .ant-pagination-item-active a {
  color: #fff;
}
.ant-table-wrapper .ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #101c44;
}
.ant-table-wrapper .ant-table-pagination.ant-pagination {
  float: left;
}
.ant-form-item {
  margin-bottom: 5px;
}
.bg-marigold-teal {
  background-color: #1cb9c8;
}
.ant-table-body table {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid #dcdcdc;
}
.ant-table-wrapper .ant-table-body {
  overflow-x: auto;
}
.right-bar-menu.ant-menu-inline {
  border-right: none;
}
.right-bar-menu .ant-menu-submenu {
  margin-left: 0px;
}
header.ant-layout-header button.ant-btn.hamburger-menu {
  background-color: transparent;
}
.hamburger-menu {
  float: right;
  height: 32px;
  padding: 6px;
  padding-top: 0px;
  margin-top: 8px;
  display: none;
  background: none;
  border-color: #fff;
}
.btn-hamburger {
  display: block;
  width: 20px;
  height: 2px;
  background: #fff;
  position: relative;
}
.btn-hamburger:after,
.btn-hamburger:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: #fff;
}
.btn-hamburger:after {
  top: auto;
  bottom: -6px;
}
.ant-drawer-body {
  padding: 0;
}
.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}
.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}
.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}
.ant-list-item-meta-content {
  word-break: break-word;
}
table.report-table td,
table.report-table th {
  border: 1px solid #1cb9c8;
  padding: 20px;
}
.image-wrapper {
  display: flex;
  align-items: flex-start;
  flex-flow: wrap;
}
.image-wrapper img {
  display: inline-flex;
}
.btn-background-navy {
  color: #101c44;
}
.border-navy-marigold {
  color: #101c44;
}

@media (max-width: 767px) {
  .hamburger-menu {
    display: inline-block;
    margin-left: auto;
  }
  .whitespace-label div.ant-form-item-label {
    line-height: 24px;
  }
}
@media print {
  .report-defect {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .report-defect span {
    width: 100%;
  }
  .image-wrapper {
    page-break-before: auto !important;
    page-break-after: auto !important;
    page-break-inside: avoid !important;
  }
  .defect-row {
    page-break-after: always !important;
  }
  .image-wrapper {
    width: 100% !important;
  }
  .image-wrapper img {
    width: 200px !important;
  }
  table.report-table {
    width: 100%;
  }
}
.whitespace-label div.ant-form-item-label > label {
  white-space: break-spaces;
}
.default-input {
  height: 45px;
}
